import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


import img1 from '../../images/logo-dark.png';


let myObject = null;;
let tok='';





class Welcome extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        ;

    }

    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }


        this.props.activateAuthLayout();
        //console.log("LLEGA")
        this.tokenSession();


    }
    async tokenSession(){
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');


            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    //this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            //this.props.history.push("/");
        }


    }






    render() {

        //console.log(correcto);
        //this.tokenSession();


        return (

            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <h4 className="page-title">Bienvenido</h4>


                            </Col>
                        </Row>
                        <div className="float-left mini-stat-img mr-4">
                            <img src={img1} alt="logo" style={{display: "block", marginLeft: "50%", width: "50%"}} />
                        </div>
                    </div>

                </Container>
            </React.Fragment>

        );
    }
}


export default withRouter(connect(null, { activateAuthLayout })(Welcome));