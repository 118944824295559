import React, { Component } from 'react';
import { Link } from 'react-router-dom';



import axios from 'axios';

let myObject = null;
var tok;

var timer=null;


if (myObject === null) {
    tok='';
}else{
    tok=myObject.logged.token;
}

class SideNav extends Component {



    constructor(props) {
        super(props);
        this.state = { tiposecs: [], primarios:[], alarmas: [], tolvas:[], marchas: [] };
    }

    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.tokenSession();
        this.obtenerSubTipoSecundarios();
        this.obtenerPrimarios();
        this.obtenerAlarmas();
        this.obtenerTolvas();
        this.CompruebaMensaje();
        timer=setInterval(this.refrescaDatos, 10000);
    }

    refrescaDatos=()=>{
        this.obtenerAlarmas();
        this.obtenerTolvas();
        this.CompruebaMensaje();
    }

    obtenerAlarmas=()=> {


        const cif = window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url") + `/alarma/${cif.replace(/['"]+/g, '')}/${window.localStorage.getItem("idCentro")}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                this.setState({
                    alarmas: JSON.parse(result).Alarmas
                });

                    //console.log(this.state.alarmas)
                    //console.log(secundarios);
                    //var actual = new Date();
                    document.getElementById('alarmas').innerText = Object.keys(this.state.alarmas).length.toString();


                    fetch(window.localStorage.getItem("url") + `/PrimariosMarcha/${cif.replace(/['"]+/g, '')}`, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            this.setState({
                                marchas: JSON.parse(result).Primarios
                            });

                                //console.log(this.state.alarmas)
                                //console.log(secundarios);
                                //var actual = new Date();
                                document.getElementById('elementos').innerText = Object.keys(this.state.marchas).length.toString();

                            }
                        )
                        .catch(error => console.log('errorazo', error));


                }
            )
            .catch(error => {
                console.log('errorazo', error);
                window.location.assign("/logout");
            });

    }

    obtenerTolvas=()=> {


        const cif = window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url") + `/tolvasAct?cif=${cif.replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro")}`, requestOptions)
            .then(response => response.text())
            .then(result => {

                this.setState({
                    tolvas: JSON.parse(result).Tolvas
                });

                    //console.log(this.state.alarmas)
                    //console.log(secundarios);
                    //var actual = new Date();

                    document.getElementById('tolvas').innerText = Object.keys(this.state.tolvas).length.toString();



                }
            )
            .catch(error => {
                console.log('errorazo', error);
                window.location.assign("/logout");
            });

    }


    componentWillUnmount() {
        clearInterval(this.timer);  }


    tokenSession(){
        try{


            axios.post('https://api.secundarios.codev.es/api/secundarios/v1/admin',{"jwt":tok}, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }} )

                .then(response =>
                {
                    //console.log(response.data);
                    var datosOb =response.data;
                    //console.log(datosOb.token);
                    if(datosOb.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return response;
                })
                .catch(error => {
                    if (error) {
                        console.log("Sorry.....Error");  }
                    this.props.history.push("/");
                });
        }catch(e)
        {
            this.props.history.push("/");
        }


    }

    obtenerSubTipoSecundarios=()=>{
        try{

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+tok);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+"/subtiposecundario/"+window.localStorage.getItem("cif").replace(/['"]+/g, '')+"/"+window.localStorage.getItem("idCentro"), requestOptions)
                .then(response => response.text())
                .then(result => {
                    //console.log(result);
                    //this.setState({ Campanias: result.Campanias });
                    var myObject = JSON.parse(result);
                    this.setState({ tiposecs: myObject.SubTipoSecundarios });

                    //console.log(myObject);
                    //console.log(centros[0].centro.nombre);
                    //console.log(Object.keys(centros).length);
                    //this.state.centros=centros;
                    var aux=[];

                    for (let i = 0; i < Object.keys(this.state.tiposecs).length; i++)
                    {
                        //console.log(centros[i].centro.nombre);
                        aux.push(this.state.tiposecs[i].tipo_sec)

                    }
                    this.setState({tiposecs: aux});
                    //window.location.reload(true);
                    //console.log(aux);
                    //this.props.history.push("/campania");
                })
                .catch(error => console.log('error', error));
        }catch(e)
        {
            this.props.history.push("/");
        }
    }

    CompruebaMensaje=()=>{
        this.setState({
            mensaje: []
        });

        const cif=window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/comunicacion?cif=${cif.replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro")}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                    this.setState({
                        mensaje: JSON.parse(result).Comunicacion
                    });
                    //alarmas=JSON.parse(result).Alarmas;

                if(this.state.mensaje[0].estado === 1){
                    document.getElementById("comunicacion").innerText="1";
                }else{
                    document.getElementById("comunicacion").innerText="0";
                }


                }
            )
            .catch(error => console.log('error', error));

    }

    obtenerPrimarios=()=>{
        try{

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+tok);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+"/primarios/"+window.localStorage.getItem("cif").replace(/['"]+/g, ''), requestOptions)
                .then(response => response.text())
                .then(result => {
                    //console.log(result);
                    //this.setState({ Campanias: result.Campanias });
                    var myObject = JSON.parse(result);
                    this.setState({primarios: myObject.Primarios});

                    //console.log(myObject);
                    //console.log(centros[0].centro.nombre);
                    //console.log(Object.keys(centros).length);
                    //this.state.centros=centros;
                    var aux=[];

                    for (let i = 0; i < Object.keys(this.state.primarios).length; i++)
                    {
                        //console.log(centros[i].centro.nombre);
                        aux.push(this.state.primarios[i])

                    }
                    this.setState({primarios: aux});
                    //window.location.reload(true);
                    //console.log(aux);
                    //this.props.history.push("/campania");
                })
                .catch(error => console.log('error', error));
        }catch(e)
        {
            this.props.history.push("/");
        }
    }

    seleccionaPrimario(id,e){
        console.log(id);
        //console.log(id.nombre);
        localStorage.setItem("idPrimario", id.primario);
        localStorage.setItem("nombre", id.nombreSubTipo);
        localStorage.setItem("tipo", "false");
        window.location.assign("/dashboardPrimario");
        //this.props.history.push("/dashboard");
    }

    seleccionaTipo(id,e){
        console.log(id);
        //console.log(id.nombre);
        localStorage.setItem("idTipo", id.id);
        localStorage.setItem("nombre", id.nombre);
        localStorage.setItem("tipo", "true");
        window.location.assign("/dashboard");

        //this.props.history.push("/dashboard");
    }
    render() {
        let menu='';
        let titulo='';
        if (window.localStorage.getItem("tipoUsuario")=== "1")
        {
            titulo=<li className={'menu-title'}>Configuraci&oacute;n</li>
            menu=<li><Link to={'/panel'} className={'waves-effect'}><i className={'mdi mdi-settings-outline'}></i><span>Panel de control</span></Link></li>;
        }

        return (

            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu" id="menu">
                        <li className="menu-title">F&aacute;brica</li>

                        <li>
                            <Link to="/fabrica" className="waves-effect">
                                <i className="mdi mdi-rhombus-split"></i>
                                <span><span className="badge badge-pill badge-success float-right" id="elementos">0</span>Estado f&aacute;brica </span>
                            </Link>
                        </li>
                        <li className="menu-title">Alarmas</li>

                        <li>
                            <Link to="/alarma" className="waves-effect">
                                <i className="mdi mdi-alarm-light-outline"></i>
                                <span><span className="badge badge-primary badge-pill float-right" id="alarmas">0</span>Alarmas f&aacute;brica </span>
                            </Link>
                        </li>
                        <li className="menu-title">ELEMENTOS</li>
                        <li>
                            <Link to="/#" className="waves-effect" style={{pointerEvents: 'none'}}>
                                <i className="mdi mdi-google-analytics"></i><span> Primario </span>
                            </Link>
                                <ul className="submenu">
                                    {this.state.primarios.map((prop, key) => {
                                        return (
                                            <li key={key}><Link style={{pointerEvents: 'auto'}} to="/dashboardPrimario" onClick={this.seleccionaPrimario.bind(this, prop)}><i className="mdi mdi-blank"></i><span>{prop.nombreSubTipo}</span></Link></li>

                                        );
                                    })}
                                </ul>

                        </li>
                        <li>
                            <Link to="/#" className="waves-effect" style={{pointerEvents: 'none'}}>
                                <i className="mdi mdi-google-analytics"></i><span> Tipo secundario </span>
                            </Link>
                                <ul className="submenu">
                                {this.state.tiposecs.map((prop, key) => {
                                    return (
                                        <li key={key}><Link className="waves-effect" style={{pointerEvents: 'auto'}} to="/dashboard" onClick={this.seleccionaTipo.bind(this, prop)}><i className="mdi mdi-blank"></i><span>{prop.nombre}</span></Link></li>
                                    );
                                })}
                                </ul>

                        </li>
                        <li className="menu-title">Energ&iacute;a (PRO)</li>

                        <li>
                            <Link to="/#" className="waves-effect" style={{pointerEvents: 'none'}}>
                                <i className="mdi mdi-flash-outline"></i>
                                <span> Panel de energ&iacute;a </span>
                            </Link>
                        </li>

                        <li className="menu-title">Tolvas (PRO)</li>

                        <li>
                            <Link to="/tolva" className="waves-effect" style={{pointerEvents: 'auto'}}>
                                <i className="mdi mdi-orbit"></i>
                                <span><span className="badge badge-primary badge-pill float-right" id="tolvas">0</span> Informaci&oacute;n tolvas </span>

                            </Link>
                        </li>

                        <li className="menu-title">Comunicaci&oacute;n (PRO)</li>

                        <li>
                            <Link to="/comunicacion" className="waves-effect">
                                <i className="mdi mdi-rhombus-split"></i>
                                <span><span className="badge badge-pill badge-success float-right" id="comunicacion">0</span>Comunicaci&oacute;n</span>
                            </Link>
                        </li>

                        <li className="menu-title">Centro</li>

                        <li>
                            <Link to="/centro" className="waves-effect">
                                <i className="mdi mdi-home-outline"></i>
                                <span> Cambiar de centro</span>
                            </Link>
                        </li>
                        {titulo}
                        {menu}

                        <li className="menu-title">Cerrar sesi&oacute;n</li>

                        <li>
                            <Link to="/logout" className="waves-effect">
                                <i className="mdi-logout"></i>
                                <span> Cerrar sesi&oacute;n</span>
                            </Link>
                        </li>



                    </ul>
                </div>

            </React.Fragment>
        );
    }
}


export default SideNav;