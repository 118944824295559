import { Component } from 'react';

export default class Logout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        };
    }

    componentDidMount() {

        try{
            var myObject = JSON.parse(window.localStorage.getItem("token"));
            const tok=myObject.logged.token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+tok);

            var raw = "";

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+"/logout", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
                    }catch(e)
                    {
                        this.props.history.push("/");
                    }





        localStorage.removeItem('token');






        this.props.history.push('/');
    }

    

    render() {
        return null;
    }
}