import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Breadcrumb, BreadcrumbItem,  Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


import ReactEcharts from 'echarts-for-react';
import ReactApexChart from 'react-apexcharts';


//import gif from '../../images/loading-81.gif';

import 'chartist/dist/scss/chartist.scss';


//Images
import img1 from '../../images/services-icon/01.png';
import img2 from '../../images/services-icon/02.png';
import img3 from '../../images/services-icon/03.png';
import img4 from '../../images/services-icon/04.png';


//import user3 from '../../images/users/user-3.jpg';

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1
        }}
    />
);

let myObject = null;
let tok='';

class Energia extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.props.activateAuthLayout();
        this.tokenSession();
    }

    async tokenSession(){

        //console.log(tok);
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');


            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };
            //console.log(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`);
            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    //this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            //this.props.history.push("/");
        }


    }

    render() {
        
        //console.log(correcto);


        let grafico;


        return (
           
            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <h4 className="page-title">Energia</h4>
                                <Breadcrumb>
                                    <BreadcrumbItem active>Bienvenido al panel de energ&iacute;a de codev</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                    <Form>
                                        <FormGroup row>
                                            <Label htmlFor="fechaDesde" sm="0" >Fecha desde</Label>
                                            <Col xl="2">
                                                <Input type="date" defaultValue="2011-08-19" id="fechaDesde" />
                                            </Col>
                                            <Label htmlFor="horaDesde" sm="0">Hora</Label>
                                            <Col xl="2">
                                                <Input type="time" defaultValue="13:45:00" id="horaDesde" />
                                            </Col>
                                            <Label htmlFor="fechaHasta" sm="0">Fecha hasta</Label>
                                            <Col xl="2">
                                                <Input type="date" defaultValue="2011-08-19" id="fechaHasta" />
                                            </Col>
                                            <Label htmlFor="horaHasta" sm="0">Hora</Label>
                                            <Col xl="2">
                                                <Input type="time" defaultValue="13:45:00" id="horaHasta" />
                                            </Col>
                                            <Col xl="2">
                                            <Button className="btn btn-primary waves-effect waves-light" block sm="1" >Buscar</Button>
                                            </Col>
                                            
                                        </FormGroup>
                                        
                                    </Form>
                                    <ColoredLine color="#FCEAE6" />
                    <Row>
                        <Col xl="3" md="6">
                            <Card className="mini-stat">
                                <CardBody>
                                    <div className="mb-4">
                                        <div className="float-left mini-stat-img mr-4">
                                            <img src={img1} alt="" />
                                        </div>
                                        <h5 className="font-16 text-uppercase mt-0 text-white-100 text-primary">Consumo total</h5>
                                        <h4 className="font-500" id='consumoAcumulado'>0 kW*h </h4>
                                        
                                    </div>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="3" md="6">
                            <Card className="mini-stat">
                                <CardBody>
                                    <div className="mb-4">
                                        <div className="float-left mini-stat-img mr-4">
                                            <img src={img2} alt="" />
                                        </div>
                                        <h5 className="font-16 text-uppercase mt-0 text-white-100 text-primary">Tiempo de uso</h5>
                                        <h4 className="font-500" id='tiempoUso'>0 </h4>
                                        
                                    </div>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="3" md="6">
                            <Card className="mini-stat ">
                                <CardBody>
                                    <div className="mb-4">
                                        <div className="float-left mini-stat-img mr-4">
                                            <img src={img3} alt="" />
                                        </div>
                                        <h5 className="font-16 text-uppercase mt-0 text-white-100 text-primary">Precio estimado</h5>
                                        <h4 className="font-500" id="precioEstimado">0 € </h4>
                                        
                                    </div>
                                    
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl="3" md="6">
                            <Card className="mini-stat">
                                <CardBody>
                                    <div className="mb-4">
                                        <div className="float-left mini-stat-img mr-4">
                                            <img src={img4} alt="" />
                                        </div>
                                        <h5 className="font-16 text-uppercase mt-0 text-white-100 text-primary">Kilos aceituna</h5>
                                        <h4 className="font-500" id='kilosAceituna'>0 kg</h4>
                                        
                                    </div>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ColoredLine color="#FCEAE6" />

                    <Button className="btn btn-secondary waves-effect waves-light" block sm="1" id="botonMostrar">

                    </Button>

                    <div id="containerGraf" style={{visibility: "hidden", padding: 25}} >

                    <Row>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <h4 className="mt-0 header-title mb-4">Consumo Kw sobre el total</h4>


                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <h4 className="mt-0 header-title mb-4">Distribuci&oacute;n de tiempo sobre el total</h4>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>

                                    <h4 className="mt-0 header-title">Consumo por elemento</h4>

                                    {grafico}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </div>
                </Container>
            </React.Fragment>
            
        );
    }
}


export default withRouter(connect(null, { activateAuthLayout })(Energia));