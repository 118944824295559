import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

//Images
import smimg1 from '../../images/fabrica/tolva.png';


let myObject = null;
let tok='';
var timer=null;
var today = new Date();

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tolvas: [],
        };

    }

    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.props.activateAuthLayout();
        this.tokenSession();

        this.EstadoFabrica();
        timer=setInterval(this.RecargaDatos, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);  }



    async EstadoFabrica(){

        this.setState({
            tolvas: []
        });
        const cif=window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/tolvas?cif=${cif.replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro")}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                    this.setState({
                        tolvas: JSON.parse(result).Tolvas
                    });

                    //console.log(primarios)
                    //console.log(secundarios);
                    var actual = new Date();
                    document.getElementById('fecha').innerText='Última actualización:  '+actual.getDate() +'-'+(actual.getMonth() + 1)+'-'+actual.getFullYear() +' '+ actual.getHours()+':'+actual.getMinutes()+':'+actual.getSeconds();

                }
            )
            .catch(error => console.log('error', error));

    }

    async RecargaDatos(){
        window.location.reload();
    }



    async tokenSession(){
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');


            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    //this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            //this.props.history.push("/");
        }


    }

    render() {
        return (
            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <h4 className="page-title">Estado de la F&aacute;brica</h4>
                                <Breadcrumb>
                                    <BreadcrumbItem active>Bienvenido al panel de codev</BreadcrumbItem>
                                </Breadcrumb>
                                <h5 id="fecha">Última actualización:  {today.getDate() +'-'+(today.getMonth() + 1)+'-'+today.getFullYear() +' '+ today.getHours()+':'+today.getMinutes()+':'+today.getSeconds()}</h5>
                            </Col>
                            <Button className="btn btn-primary waves-effect waves-light" block sm="1" onClick={this.RecargaDatos}>Recargar estado</Button>
                        </Row>
                    </div>

                    <Row>
                        {this.state.tolvas.map((prop, key) => {
                            var estado="";
                            var color="";
                            if (prop.estado === 0){
                                estado="Apagada";
                                color="red"
                            }else if(prop.estado === 1){
                                estado="Pausada";
                                color="orange"
                            }else{
                                estado="Iniciada";
                                color="green"
                            }
                            return (
                                <Col md="6" lg="6" xl="3" key={key}>
                                    <Card>
                                        <img className="card-img-top img-fluid" src={smimg1} alt="tolvas"/>
                                        <CardBody>
                                            <h4 className="card-title font-16 mt-0">{prop.nombre}</h4>

                                        </CardBody>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Kilos: {parseFloat(prop.kilos.toFixed(1))} Kg</li>
                                            <li className="list-group-item">Calidades: {prop.calidad.replace('%20',' ')}</li>
                                            <li className="list-group-item">F. Partida: {moment(prop.fecha_p).format('DD/MM/YYYY hh:mm:ss')}</li>
                                            <li className="list-group-item">F. Ticket: {moment(prop.fecha_f).format('DD/MM/YYYY hh:mm:ss')}</li>
                                            <li className="list-group-item">Estado: <span style={{color: color}} >{estado}</span></li>
                                            <li className="list-group-item">Camino: <br/> {prop.camino}
                                            </li>
                                        </ul>
                                    </Card>

                                </Col>
                            );})}
                    </Row>


                </Container>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Index));