import React, { Component } from 'react';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';


import axios from 'axios';

let myObject = null;
var tok;
var centros;


//const idUsuario=myObject.idUsuario;

class Uicards extends Component {
    constructor(props) {
        super(props);
        this.state = { centros: [] };
    }
    tokenSession(){
        try{

            
            axios.post('https://api.secundarios.codev.es/api/secundarios/v1/admin',{"jwt":tok}, {
            headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
                }} )

            .then(response =>
            {
                //console.log(response.data);
                var datosOb =response.data;
                //console.log(datosOb.token);
                if(datosOb.token === ""){
                    this.props.history.push("/");
                }else{
                    return true;
                }
                return response;
            })
            .catch(error => {
            if (error) {
                console.log("Sorry.....Error");  }
                this.props.history.push("/");
                });
        }catch(e)
        {
            this.props.history.push("/");
        }

        
    }

    obtenerCentros(){
        try{
            
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+tok);
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+"/usuarioCentros/usuario/"+window.localStorage.getItem("idUsuario"), requestOptions)
            .then(response => response.text())
            .then(result => {
                //console.log(result);
                this.setState({ Centros: result.Centros });
                //console.log((result))
                var objeto = JSON.parse(result);
                centros=objeto.Centros;
                //console.log(myObject);
                //console.log(centros[0].centro.nombre);
                //console.log(Object.keys(centros).length);
                //this.state.centros=centros;
                var aux=[];
                
                for (let i = 0; i < Object.keys(centros).length; i++)
                {
                    //console.log(centros[i].centro.nombre);
                    aux.push(centros[i].centro)
                    
                }
                this.setState({centros: aux});
                this.forceUpdate()
                //window.location.reload(true);

            })
            .catch(error => {
                console.log('error', error)
                this.obtenerCentros();
            });

                    }catch(e)
                    {
                        this.props.history.push("/");
                    }
    }
    seleccionaCentro(id,e){
        //console.log(id.id);
        //console.log(id.nombre);

        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        localStorage.setItem("idCentro", id.id);
        localStorage.setItem("nombreCentro", id.nombre);
        localStorage.setItem("cif", id.cif);

        this.props.history.push("/campania");
    }

    componentDidMount() {
        //console.log(window.localStorage.getItem("idUsuario"));
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.tokenSession();
        this.obtenerCentros();
    }


    render() {

        return (
            <React.Fragment>
                <Container fluid>
                <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <h4 className="page-title">Seleccione un centro para poder continuar</h4>
                                
                            </Col>
                            <Col sm="6">
                                <div className="float-right d-none d-md-block">
                                    
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col md="6" lg="6" xl="3">
                            <Card>
                                
                            </Card>
                        </Col>

                        <Col md="6" lg="6" xl="3">
                            <Card>
                                
                            </Card>
                        </Col>

                        <Col md="6" lg="6" xl="3">
                            <Card>
                                
                            </Card>
                        </Col>

                        <Col md="6" lg="6" xl="3">
                            <Card>
                                
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            
                        </Col>
                        <Col md="6">
                           
                        </Col>
                    </Row>
                <Row>
                {this.state.centros.map((prop, key) => {
            return (
        <Col lg="3" key={key}>
            <div className="card card-body">
                <h4 className="card-title font-16 mt-0 text-center" id={prop.id} name={prop.nombre}>{prop.nombre}</h4>
                <Button className="btn btn-primary waves-effect waves-light" onClick={this.seleccionaCentro.bind(this, prop)}>Seleccionar este centro</Button>
            </div>
        </Col>
            );
         })}
                    </Row>
                </Container>
            </React.Fragment>
            
        );
    }
}

export default withRouter(Uicards);