import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import axios from 'axios';

let myObject = null;
var tok;
var campanias;

if (myObject === null) {
    tok='';
}else{
    tok=myObject.logged.token;
}

class Uidropdowns extends Component {
    constructor(props) {
        super(props);
        this.state = { campanias: [] };
    }

    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.tokenSession();
        this.obtenerCampanias();
    }
    seleccionaElemento(id,e){
        console.log(id);
        //console.log(id.nombre);
        localStorage.setItem("idCampania", id.id);

        localStorage.setItem("cif", id.cif);
        this.props.history.push("/welcome");
    }
    tokenSession(){
        try{


            axios.post('https://api.secundarios.codev.es/api/secundarios/v1/admin',{"jwt":tok}, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }} )

                .then(response =>
                {
                    //console.log(response.data);
                    var datosOb =response.data;
                    //console.log(datosOb.token);
                    if(datosOb.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return response;
                })
                .catch(error => {
                    if (error) {
                        console.log("Sorry.....Error");  }
                    this.props.history.push("/");
                });
        }catch(e)
        {
            this.props.history.push("/");
        }


    }
    obtenerCampanias(){
        try{

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+tok);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+"/campanias/"+window.localStorage.getItem("idCentro"), requestOptions)
                .then(response => response.text())
                .then(result => {
                    //console.log(result);
                    this.setState({ Campanias: result.Campanias });
                    var myObject = JSON.parse(result);
                    campanias=myObject.Campanias;
                    //console.log(myObject);
                    //console.log(centros[0].centro.nombre);
                    //console.log(Object.keys(centros).length);
                    //this.state.centros=centros;
                    var aux=[];

                    for (let i = 0; i < Object.keys(campanias).length; i++)
                    {
                        //console.log(centros[i].centro.nombre);
                        aux.push(campanias[i])

                    }
                    this.setState({campanias: aux});
                    //window.location.reload(true);
                    //console.log(aux);
                    //this.props.history.push("/campania");
                })
                .catch(error => console.log('error', error));
        }catch(e)
        {
            this.props.history.push("/");
        }
    }
    render() {
        return (
            <React.Fragment>
                <Container fluid>

                    <Row style={{ marginTop: "18%"}}>
                        <Col lg="3"></Col>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <h4 className="mt-0 header-title">Campa&ntilde;a</h4>
                                    <p className="text-muted m-b-30">Seleccione una campa&ntilde;a para poder continuar</p>
                                    <div className="">
                                        <ButtonGroup className="mo-mb-2" style={{ marginLeft: "35%"}}>
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="secondary" size="lg" caret>
                                                    Seleccione{' '}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.state.campanias.map((prop, key) => {
                                                        return (
                                                            <DropdownItem key={key} onClick={this.seleccionaElemento.bind(this, prop)}>{prop.campania}</DropdownItem>
                                                        );
                                                    })}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3"></Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Uidropdowns));