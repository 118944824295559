import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, Table, TabContent, TabPane, Breadcrumb, BreadcrumbItem, Form, FormGroup, Input, Label, Button  } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

var today = new Date();

let myObject = null;
let tok='';
var timer=null;

//var alarmasH=[];

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1
        }}
    />
);

class Uitabsaccordions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            activeTab1: '5',
            activeTab_border: '9',
            activeTab_border1: '13',
            col1: true,
            col2: false,
            col3: false,
            col5: true,
            alarmas: [],
            alarmasH: [],
            default_date: new Date(), default: false, start_date: new Date(), monthDate: new Date(), yearDate: new Date(), end_date: new Date(), date: new Date(),
            default_date_hasta: new Date(),
        };

        this.handleDefault = this.handleDefault.bind(this);
        this.handleDefaultHasta = this.handleDefaultHasta.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);

        this.t_col1 = this.t_col1.bind(this);
        this.t_col2 = this.t_col2.bind(this);
        this.t_col3 = this.t_col3.bind(this);
        this.t_col5 = this.t_col5.bind(this);

        this.t_border = this.t_border.bind(this);
        this.t_border1 = this.t_border1.bind(this);

    }
    handleDefault(date) {
        this.setState({ default_date: date });
    }
    handleDefaultHasta(date) {
        this.setState({ default_date_hasta: date });
    }

    componentDidMount() {
        this.props.activateAuthLayout();
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.props.activateAuthLayout();
        this.tokenSession();

        this.EstadoFabrica();


        timer=setInterval(this.RecargaDatos, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);  }



    EstadoFabrica=()=>{
        this.setState({
            alarmas: []
        });

        const cif=window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/alarma/${cif.replace(/['"]+/g, '')}/${window.localStorage.getItem("idCentro")}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                this.setState({
                    alarmas: JSON.parse(result).Alarmas
                });
                //alarmas=JSON.parse(result).Alarmas;
                    //console.log(this.state.alarmas)
                    //console.log(secundarios);
                    var actual = new Date();
                    document.getElementById('fecha').innerText='Última actualización:  '+actual.getDate() +'-'+(actual.getMonth() + 1)+'-'+actual.getFullYear() +' '+ actual.getHours()+':'+actual.getMinutes()+':'+actual.getSeconds();

                }
            )
            .catch(error => console.log('error', error));

    }

    RecargaDatos=()=>{
        this.EstadoFabrica();
    }

     buscar = () =>{

        const fechaDesde=document.getElementById('fechaDesde').value.substr(6,4)+"-"+document.getElementById('fechaDesde').value.substr(3,2)+"-"+document.getElementById('fechaDesde').value.substr(0,2)
        const horaDesde=document.getElementById('horaDesde').value;
        const fechaHasta=document.getElementById('fechaHasta').value.substr(6,4)+"-"+document.getElementById('fechaHasta').value.substr(3,2)+"-"+document.getElementById('fechaHasta').value.substr(0,2)
        const horaHasta=document.getElementById('horaHasta').value;

        const cif=window.localStorage.getItem("cif");

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


            fetch(window.localStorage.getItem("url")+`/alarmafa?cif=${cif.replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro").replace(/['"]+/g, '')}&fechaI=${(fechaDesde+' '+horaDesde).replace(/['"]+/g, '')}&fechaF=${(fechaHasta+' '+horaHasta).replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                        //console.log(result);
                    this.setState({
                        alarmasH: JSON.parse(result).Alarmas
                    });
                        //console.log(alarmas);
                        //console.log(secundarios);
                    }
                )
                .catch(error => console.log('error', error));

    }

    async tokenSession(){
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');


            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    //this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            //this.props.history.push("/");
        }


    }

    t_col1() {
        this.setState({ col1: !this.state.col1 });
    }
    t_col2() {
        this.setState({ col2: !this.state.col2 });
    }
    t_col3() {
        this.setState({ col3: !this.state.col3 });
    }
    t_col5() {
        this.setState({ col5: !this.state.col5 });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });
        }
    }


    t_border(tab) {
        if (this.state.activeTab_border !== tab) {
            this.setState({
                activeTab_border: tab
            });
        }
    }
    t_border1(tab) {
        if (this.state.activeTab_border1 !== tab) {
            this.setState({
                activeTab_border1: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <h4 className="page-title">Alarmas en F&aacute;brica</h4>
                                <Breadcrumb>
                                    <BreadcrumbItem active>Bienvenido al panel de codev</BreadcrumbItem>
                                </Breadcrumb>
                                <h5 id="fecha">Última actualización:  {today.getDate() +'-'+(today.getMonth() + 1)+'-'+today.getFullYear() +' '+ today.getHours()+':'+today.getMinutes()+':'+today.getSeconds()}</h5>

                            </Col>
                            <Button className="btn btn-primary waves-effect waves-light" block sm="1" onClick={this.RecargaDatos}>Recargar estado</Button>

                        </Row>
                    </div>

                    <Row>
                        <Col >
                            <Card>
                                <CardBody>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                                     onClick={() => { this.toggle('1'); }} >
                                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                <span className="d-none d-sm-block">Estado actual</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                                                     onClick={() => { this.toggle('2'); }} >
                                                <span className="d-block d-sm-none"><i className="fas fa-user"></i></span>
                                                <span className="d-none d-sm-block">Hist&oacute;rico (Pro)</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={this.state.activeTab} >
                                        <TabPane tabId="1" className="p-3">
                                            <Row>
                                                <Col lg="12">
                                                    <Card>
                                                        <CardBody>
                                                           <Table responsive className="mb-0">
                                                                <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Fecha</th>
                                                                    <th>Alarma</th>
                                                                    <th>Estado</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.alarmas.map((prop, key) => {
                                                                    if (prop.estado === 2){
                                                                        return (
                                                                            <tr className="table-danger" key={key}><th scope="row">{prop.id}</th><td>{moment(prop.fecha).format('DD/MM/YYYY hh:mm:ss')}</td><td>{prop.alarma}</td><td>Activa</td></tr>
                                                                        );
                                                                    }else{
                                                                        return (
                                                                            <tr className="table-warning" key={key}><th scope="row">{prop.id}</th><td>{moment(prop.fecha).format('DD/MM/YYYY hh:mm:ss')}</td><td>{prop.alarma}</td><td>Inactiva</td></tr>
                                                                        );
                                                                    }

                                                                })}
                                                                </tbody>
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2" className="p-3" >

                                                <Form>
                                                    <FormGroup row>
                                                        <Label htmlFor="fechaDesde" sm="0">Fecha desde</Label>
                                                        <Col>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={this.state.default_date}
                                                                onChange={this.handleDefault}
                                                                dateFormat="dd/MM/yyyy"
                                                                id="fechaDesde"
                                                            />
                                                        </Col>
                                                        <Label htmlFor="horaDesde" sm="0">Hora</Label>
                                                        <Col>
                                                            <Input type="time" defaultValue="13:45:00" id="horaDesde" />
                                                        </Col>
                                                        <Label htmlFor="fechaHasta" sm="0">Fecha hasta</Label>
                                                        <Col >

                                                            <DatePicker
                                                                className="form-control"
                                                                selected={this.state.default_date_hasta}
                                                                onChange={this.handleDefaultHasta}
                                                                dateFormat="dd/MM/yyyy"
                                                                id="fechaHasta"
                                                            />
                                                        </Col>
                                                        <Label htmlFor="horaHasta" sm="0">Hora</Label>
                                                        <Col >
                                                            <Input type="time" defaultValue="13:45:00" id="horaHasta" />
                                                        </Col>
                                                        <Col>
                                                            <Button className="btn btn-primary waves-effect waves-light" block sm="1" onClick={this.buscar}>Buscar</Button>
                                                        </Col>

                                                    </FormGroup>

                                                </Form>

                                                <ColoredLine color="#FCEAE6" />
                                                <Row>
                                                    <Col lg="12">
                                                        <Card>
                                                            <CardBody>
                                                                <Table responsive className="mb-0">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Fecha</th>
                                                                        <th>Alarma</th>
                                                                        <th>Estado</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.alarmasH.map((prop, key) => {
                                                                        if (prop.estado === 2){
                                                                            return (
                                                                                <tr className="table-danger" key={key}><th scope="row">{prop.id}</th><td>{moment(prop.fecha).format('DD/MM/YYYY hh:mm:ss')}</td><td>{prop.alarma}</td><td>Activa</td></tr>
                                                                            );
                                                                        }else{
                                                                            return (
                                                                                <tr className="table-warning" key={key}><th scope="row">{prop.id}</th><td>{moment(prop.fecha).format('DD/MM/YYYY hh:mm:ss')}</td><td>{prop.alarma}</td><td>Inactiva</td></tr>
                                                                            );
                                                                        }

                                                                    })}
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Uitabsaccordions));