import Energia from './pages/Energia';
import Dashboard from './pages/Dashboard/dashboard';
import DashboardPrimario from './pages/Dashboard/dashboardPrimario';

import Pageslogin from './pages/Login';
import Logout from './pages/Logout';

import Centro from './pages/Centros';

import Pruebas from './pages/Pruebas';

import Campania from './pages/Campania';

import Panel from './pages/Panel';

import Welcome from './pages/Welcome';

import Fabrica from './pages/Fabrica';


import Alarma from './pages/Alarma';

import Tolva from './pages/Tolvas';

import Comunicacion from './pages/Comunicacion';


const routes = [

    // public Routes
    { path: '/login', component: Pageslogin, ispublic: true },
    { path: '/logout', component: Logout, ispublic: true },

    //Pruebas
    { path: '/pruebas', component: Pruebas, ispublic: true },

    //Centros
    { path: '/centro', component: Centro},

    { path: '/campania', component: Campania},
    { path: '/panel', component: Panel},

    // Dashnoard
    { path: '/dashboard', component: Dashboard },
    { path: '/dashboardPrimario', component: DashboardPrimario },
    { path: '/energia', component: Energia },
    { path: '/welcome', component: Welcome },
    { path: '/fabrica', component: Fabrica },

    { path: '/alarma', component: Alarma },
    { path: '/tolva', component: Tolva },
    { path: '/comunicacion', component: Comunicacion },
    { path: '/', component: Pageslogin, ispublic: true},

];

export default routes;