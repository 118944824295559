import React, { Component } from 'react';
import { Container, Row, Col, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


let myObject = null;
let tok='';
var idMensaje='';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            mensaje: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        var tipoComunicacion=0;
        if (document.getElementById("bocina").checked === true)
        {
            tipoComunicacion=1;
        }else{
            tipoComunicacion=2;
        }
        const cif=window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
console.log(idMensaje)
        if (idMensaje === ''){
            fetch(window.localStorage.getItem("url")+`/comunicacion?cif=${cif.replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro")}&mensaje=${this.state.value}&estado=${tipoComunicacion.toString()}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    }
                )
                .catch(error => console.log('error', error));
        }else{
            console.log("Actualizar")
            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(window.localStorage.getItem("url")+`/comunicacionUpdate?cif=${cif.replace(/['"]+/g, '')}&id=${idMensaje}&mensaje=${this.state.value}&estado=${tipoComunicacion.toString()}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    }
                )
                .catch(error => console.log('error', error));
        }


        alert("Mensaje enviado: "+this.state.value);
        event.preventDefault();
        window.location.assign("/welcome");
    }

    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.props.activateAuthLayout();
        this.tokenSession();
        this.CompruebaMensaje();
    }

    CompruebaMensaje=()=>{
        this.setState({
            mensaje: []
        });

        const cif=window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/comunicacion?cif=${cif.replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro")}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                    this.setState({
                        mensaje: JSON.parse(result).Comunicacion
                    });
                    //alarmas=JSON.parse(result).Alarmas;
                //console.log(this.state.mensaje[0].id)
                try{
                    idMensaje=this.state.mensaje[0].id.toString();

                    document.getElementById("mensaje").innerHTML=this.state.mensaje[0].mensaje;
                }catch(error){

                }

                }
            )
            .catch(error => console.log('error', error));

    }


    async tokenSession(){
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');


            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    //this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            //this.props.history.push("/");
        }


    }

    render() {
        return (
            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="12">
                                <h4 className="page-title">Comunicaci&oacute;n</h4>
                                <Breadcrumb>
                                    <BreadcrumbItem active>Bienvenido al panel de codev</BreadcrumbItem>
                                </Breadcrumb>
                                <form onSubmit={this.handleSubmit}>

                                        <textarea id="mensaje" value={this.state.value} onChange={this.handleChange} className="form-control" rows="10"/>
                                <br/>
                                    <label><input type="checkbox" id="bocina" value="bocina"/> Hacer saltar la bocina</label>
                                    <input className="btn btn-primary waves-effect waves-light" type="submit" value="Enviar mensaje" style={{width: '100%'}}/>
                                </form>
                            </Col>

                        </Row>
                    </div>




                </Container>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Index));