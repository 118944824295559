import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import logodark from '../../images/logo-dark.png';


export default class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            message : this.props.location.state?this.props.location.state.message: '',
        };
    }


    signIn = () => {
        const data = { nombre: this.nombre, password: this.password };
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        };

        fetch('https://api.secundarios.codev.es/api/secundarios/v1/login', requestInfo)
        .then(response => {
            if(response.ok) {
                return response.json();
            }
            throw new Error("Login incorrecto...");
        })
        .then(token => {
            //console.log(JSON.stringify(token));
            window.localStorage.setItem("token", JSON.stringify(token));
            window.localStorage.setItem("idUsuario", JSON.stringify(token.id));
            window.localStorage.setItem("cif", JSON.stringify(token.cif));
            window.localStorage.setItem("tipoUsuario", JSON.stringify(token.tipo));
            this.props.history.push("/centro");
            return;
        })
        .catch(e => {
            this.setState({ message: e.message });
        }); 
    }

    render() {

        return (
            <React.Fragment>

                <div className="accountbg"></div>

                <div className="wrapper-page account-page-full">

                    <Card>
                        <CardBody>

                            <div className="text-center">
                                <Link to="/" className="logo"><img src={logodark} height="102" alt="logo" /></Link>
                            </div>

                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">Bienvenido</h4>
                                <p className="text-muted text-center">Inicia sesi&oacute;n para continuar.</p>
                                <hr  className="my-3"/>
                                    {
                                        this.state.message !== ''? (
                                            <Alert color="danger" className="text-center"> {this.state.message} </Alert>
                                        ) : ''
                                    }
                                <Form className="form-horizontal m-t-30" action="/">

                                    <FormGroup>
                                        <Label for="username">Email</Label>
                                        <Input type="text" id="nombre" onChange={e => this.nombre = e.target.value} placeholder="Introduzca su nombre de usuario" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="userpassword">Password</Label>
                                        <Input type="password" id="password" onChange={e => this.password = e.target.value} placeholder="Introduzca su password" />
                                    </FormGroup>

                                    <Row className="form-group m-t-20">
                                        <Col sm="6">
                                            
                                        </Col>
                                        <Col sm="12" className="text-right">
                                            <Button color="primary" className="w-md waves-effect waves-light" block onClick={this.signIn}>Iniciar sesi&oacute;n</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </div>

                        </CardBody>
                    </Card>

                    <div className="m-t-40 text-center">
                        <p>© {new Date().getFullYear()} Codev</p>
                    </div>

                </div>
            </React.Fragment>
            
        );
    }
}