import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1
        }}
    />
);

let optionGroup = [];

let myObject = null;
let tok='';
let usuarios=[];
let centros=[];

function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
}

class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroup: null,
            selectedMulti: null,
            usuarios: []
        };
        //this.handleMulti = this.handleMulti.bind(this);
    }

    rellenaCentros(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+"/centros", requestOptions)
            .then(response => response.text())
            .then(result => {
                //console.log(result)
                var centrosAux=[];

                var myObject = JSON.parse(result);
                centrosAux=myObject.Centros;
                //console.log(primarios)
                var aux=[];

                for (let i = 0; i < Object.keys(centrosAux).length; i++)
                {
                    //console.log(centros[i].centro.nombre);
                    aux.push({label: centrosAux[i].nombre, value: centrosAux[i].id})

                }

                //console.log(aux)

                optionGroup.push(
                {
                    label: "Centros",
                        options: aux
                }
                );
            })
            .catch(error => console.log('error', error));
    }

    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        //console.log(myObject);
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.props.activateAuthLayout();
        this.tokenSession();
        this.rellenaCentros();
    }

    //Select
    handleMulti = (selectedMulti) => {
        this.setState({ selectedMulti });
    }

    tokenSession(){
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');


            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            this.props.history.push("/");
        }


    }

    anadirUsuario(){
        const cif=window.localStorage.getItem("cif");
        const nombreUsuario=document.getElementById("nombreUsuario").value;
        const passUsuario=document.getElementById("passUsuario").value;


        if(nombreUsuario !== "" && passUsuario !== "" && this.state.selectedMulti.length > 0){

            var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/registro?nombre=${nombreUsuario.replace(/['"]+/g, '')}&password=${passUsuario.replace(/['"]+/g, '')}&cif=${cif.replace(/['"]+/g, '')}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                var myObject = JSON.parse(result);


                for (let i = 0; i < this.state.selectedMulti.length; i++) {
                    myHeaders = new Headers();
                    myHeaders.append("Authorization", "Bearer "+tok);

                    var requestOptions = {
                        method: 'PUT',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(window.localStorage.getItem("url")+`/usuarioCentro?usuario_id=${myObject.id}&centro_id=${this.state.selectedMulti[i].value}`, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            document.getElementById("nombreUsuario").value="";
                            document.getElementById("passUsuario").value="";
                            this.setState({selectedMulti: []});
                            //this.state.selectedMulti=[];
                        })
                        .catch(error => console.log('error', error));
                }

            })
            .catch(error => console.log('error', error));

        }





    }
    obtenUsuarios(){
        if (document.getElementById("botonMostrar").innerText === "Mostrar usuarios") {

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + tok);


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/usuariosCentros`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    myObject = JSON.parse(result);
                    centros = myObject.Centros;

                    var aux = [];
                    for (let i = 0; i < Object.keys(centros).length; i++) {
                        usuarios.push({
                            id: centros[i].usuario_id,
                            nombre: centros[i].usuario.nombre,
                            centros: []
                        });
                    }
                    var distintos = removeDuplicates(usuarios, "id");
                    for (let x = 0; x < distintos.length; x++) {
                        aux=[];
                        for (let i = 0; i < Object.keys(centros).length; i++) {
                            if (distintos[x].id === centros[i].usuario.id) {
                                aux.push(centros[i].centro.nombre)
                            }
                        }
                        distintos[x].centros = aux;
                    }
                    console.log(distintos)
                    this.setState({usuarios: distintos});
                    document.getElementById('usuariosListado').style.visibility = 'visible'
                    document.getElementById("botonMostrar").innerText="Ocultar usuarios"
                })
                .catch(error => console.log('error', error));
        }else{
            document.getElementById("botonMostrar").innerText="Mostrar usuarios"
            document.getElementById('usuariosListado').style.visibility = 'hidden'
        }


    }
    eliminaCentro(datos,e){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);


        var raw = "";

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/usuarioCentros/usuario/${datos}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));


        requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/usuario/${datos}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        window.location.reload();

    }

    render() {
        const { selectedMulti } = this.state;

        return (
            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <h4 className="page-title">Configuraci&oacute;n</h4>

                                <Breadcrumb>
                                    <BreadcrumbItem active>Bienvenido al panel de configuraci&oacute;n de codev</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                    <Form>
                        <FormGroup row>
                            <Label htmlFor="nombreUsuario" sm="0" >Nombre de usuario</Label>
                            <Col xl="2">
                                <Input type="text" id="nombreUsuario" placeholder="Nombre de usuario"/>
                            </Col>
                            <Label htmlFor="passUsuario" sm="0">Contrase&ntilde;a</Label>
                            <Col xl="2">
                                <Input type="password" id="passUsuario" placeholder="Password" />
                            </Col>
                            <Label sm="0" htmlFor="centros">Seleccione centros</Label>
                            <Col xl="2">
                                <Select id="centros" value={selectedMulti} isMulti={true} onChange={this.handleMulti} options={optionGroup} />
                            </Col>
                            <Col xl="2">
                                <Button className="btn btn-primary waves-effect waves-light" block sm="0" onClick={this.anadirUsuario.bind(this)}>A&ntilde;adir usuario</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                    <ColoredLine color="#FCEAE6" />
                    <Button className="btn btn-secondary waves-effect waves-light" block sm="1" onClick={this.obtenUsuarios.bind(this)} id="botonMostrar">Mostrar usuarios</Button>
                    <ColoredLine color="#FCEAE6" />
                    <Row id ='usuariosListado' style={{visibility: 'hidden'}}>
                        {this.state.usuarios.map((prop, key) => {
                             return (
                                 <Col md="6" lg="6" xl="3" key={key}>
                                     <Card>
                                         <CardBody>
                                             <h4 className="card-title font-16 mt-0">Usuario: {prop.nombre}</h4>

                                         </CardBody>
                                         {prop.centros.map((proper, key) => {
                                         return (
                                         <ul className="list-group list-group-flush" key={key}>
                                             <li className="list-group-item">{proper}{' '}</li>

                                         </ul>
                                         );})}
                                         <Button id ={prop.id} type="button" outline color="danger" onClick={this.eliminaCentro.bind(this, prop.id)} className="waves-effect waves-light float-right">Eliminar</Button>
                                     </Card>
                                 </Col>
                             );
                         })}
                    </Row>

                </Container>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Panel));
//{this.state.centros.map((prop, key) => {
//                             return (
//                                 <Col md="6" lg="6" xl="3" key={key}>
//                                     <Card>
//                                         <CardBody>
//                                             <h4 className="card-title font-16 mt-0">Nombre de usuario: {prop}</h4>
//                                             <p className="card-text">Centros habilitados</p>
//                                         </CardBody>
//                                         <ul className="list-group list-group-flush">
//                                             <li className="list-group-item">Cras justo odio</li>
//                                             <li className="list-group-item">Dapibus ac facilisis in</li>
//                                         </ul>
//                                     </Card>
//                                 </Col>
//                             );
//                         })}