import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Breadcrumb, BreadcrumbItem, UncontrolledPopover, PopoverHeader, PopoverBody} from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';



//Images
import smimg1 from '../../images/fabrica/batidora_3cuerpos_50.png';
import smimg2 from '../../images/fabrica/molino_izq_50.png';
import smimg3 from '../../images/fabrica/centrifuga_v1_50.png';
import smimg4 from '../../images/fabrica/decanter_53x_552_solo_50.png';

let myObject = null;
let tok='';
var timer=null;
var today = new Date();
var anterior="";
var estructura=``;
var cuerpo=``;
var contador=0;


class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primarios: [],
            secundarios: [],

        };

    }


    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }
        this.props.activateAuthLayout();
        this.tokenSession();

        this.DevuelveSecundariosActivos();
        timer=setInterval(this.RecargaDatos, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);  }
    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }



    EstadoFabrica(){

        this.setState({
            primarios: [],
            showPopup: false
        });
        const cif=window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url")+`/primarios/${cif.replace(/['"]+/g, '')}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                    this.setState({
                        primarios: JSON.parse(result).Primarios
                    });

                    //console.log(primarios)
                    //console.log(secundarios);
                    var actual = new Date();
                    document.getElementById('fecha').innerText='Última actualización:  '+actual.getDate() +'-'+(actual.getMonth() + 1)+'-'+actual.getFullYear() +' '+ actual.getHours()+':'+actual.getMinutes()+':'+actual.getSeconds();

                }
            )
            .catch(error => console.log('error', error));

    }

    RecargaDatos=()=>{
        //window.location.reload();
        this.DevuelveSecundariosActivos();
    }



    DevuelveSecundariosActivos=()=>{

        this.setState({
            secundarios: []
        });


        const cif = window.localStorage.getItem("cif");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.localStorage.getItem("url") + `/secundariosLast?cif=${cif.replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro").replace(/['"]+/g, '')}`, requestOptions)
            .then(response => response.text())
            .then(result => {

                    this.setState({
                        secundarios: JSON.parse(result).Secundarios
                    });
                var actual = new Date();
                document.getElementById('fecha').innerText='Última actualización:  '+actual.getDate() +'-'+(actual.getMonth() + 1)+'-'+actual.getFullYear() +' '+ actual.getHours()+':'+actual.getMinutes()+':'+actual.getSeconds();

                }
            )
            .catch(error => console.log('error', error));

        //console.log(document.getElementById(prop.id).value)
        /*<UncontrolledPopover placement="top" target="Popovertop">
            <PopoverHeader>Estado actual</PopoverHeader>
            <PopoverBody id={prop.id}></PopoverBody>
        </UncontrolledPopover >*/
    }
    async tokenSession(){
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');


            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    //this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            //this.props.history.push("/");
        }


    }

    render() {
        return (
            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">

                            <Col sm="6">
                                <h4 className="page-title">Estado de la F&aacute;brica</h4>
                                <Breadcrumb>
                                    <BreadcrumbItem active>Bienvenido al panel de codev</BreadcrumbItem>
                                </Breadcrumb>
                                <h5 id="fecha">Última actualización:  {today.getDate() +'-'+(today.getMonth() + 1)+'-'+today.getFullYear() +' '+ today.getHours()+':'+today.getMinutes()+':'+today.getSeconds()}</h5>
                            </Col>
                            <Button className="btn btn-primary waves-effect waves-light" block sm="1" onClick={this.RecargaDatos}>Recargar estado</Button>
                        </Row>
                    </div>

                    <Row id="contenedor">
                        {
                            this.state.secundarios.map((prop, key) => {
                                    contador+=1;
                                //console.log(anterior)


                                var pie=`</span>
                                            </div>

                                        </Col></div>`



                                    if (prop.etiqueta.includes("Molino")){

                                        if(prop.primarios.marcha === 1) {
                                            var cabecera='<div style="margin-right: 30px;margin-top: 30px; border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: green"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg2+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                            /*if (anterior != prop.etiqueta){
                                                //console.log("distinto")
                                                cuerpo=`<li className="list-group-item"
                                                        style={{color: "green"}}>${prop.tipo_sec.nombre}: ${prop.secundarios[0].valor} ${prop.medida}</li>`
                                            }else{*/
                                                //console.log("igual")
                                            if (prop.secundarios.length >0){
                                                cuerpo=cuerpo+'<p className="list-group-item" style="margin-left: 15px;"><strong>'+prop.tipo_sec.nombre+':</strong>'+ prop.secundarios[prop.secundarios.length-1].valor+' '+prop.medida+'</p>'
                                            }

                                            //}
                                            //console.log(prop.secundarios.length)

                                        }else{
                                            var cabecera='<div style="margin-right: 30px;margin-top: 30px; border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: darkred"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg2+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                        }

                                    }else if (prop.etiqueta.includes("Batidora")){
                                        var cabecera='<div style="margin-right: 30px;margin-top: 30px;border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: green"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg1+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                        if(prop.primarios.marcha === 1) {

                                            /*if (anterior != prop.etiqueta){
                                                //console.log("distinto")
                                                cuerpo=`<li className="list-group-item"
                                                        style={{color: "green"}}>${prop.tipo_sec.nombre}: ${prop.secundarios[0].valor} ${prop.medida}</li>`
                                            }else{*/
                                            //console.log("igual")
                                            if (prop.secundarios.length >0){
                                                cuerpo=cuerpo+'<p className="list-group-item" style="margin-left: 15px;"><strong>'+prop.tipo_sec.nombre+':</strong>'+ prop.secundarios[0].valor+' '+prop.medida+'</p>'
                                            }

                                            //}
                                            //console.log(prop.secundarios.length)

                                        }else{
                                            var cabecera='<div style="margin-right: 30px;margin-top: 30px; border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: darkred"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg1+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                        }

                                    }else if (prop.etiqueta.includes("Decanter")){
                                        var cabecera='<div style="margin-right: 30px;margin-top: 30px;border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: green"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg4+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                        if(prop.primarios.marcha === 1) {

                                            /*if (anterior != prop.etiqueta){
                                                //console.log("distinto")
                                                cuerpo=`<li className="list-group-item"
                                                        style={{color: "green"}}>${prop.tipo_sec.nombre}: ${prop.secundarios[0].valor} ${prop.medida}</li>`
                                            }else{*/
                                                //console.log("igual")
                                            if (prop.secundarios.length >0){
                                                cuerpo=cuerpo+'<p className="list-group-item" style="margin-left: 15px;"><strong>'+prop.tipo_sec.nombre+':</strong>'+ prop.secundarios[0].valor+' '+prop.medida+'</p>'
                                            }
                                            //}
                                            //anterior=prop.etiqueta;

                                        }else{
                                            var cabecera='<div style="margin-right: 30px;margin-top: 30px; border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: darkred"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg4+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                        }

                                    }else if (prop.etiqueta.includes("Centrifuga")){
                                        var cabecera='<div style="margin-right: 30px;margin-top: 30px;border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: green"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg3+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                        if(prop.primarios.marcha === 1) {

                                            /*if (anterior != prop.etiqueta){
                                                //console.log("distinto")
                                                cuerpo=`<li className="list-group-item"
                                                        style={{color: "green"}}>${prop.tipo_sec.nombre}: ${prop.secundarios[0].valor} ${prop.medida}</li>`
                                            }else{*/
                                            //console.log("igual")
                                            if (prop.secundarios.length >0){
                                                cuerpo=cuerpo+'<p className="list-group-item" style="margin-left: 15px;"><strong>'+prop.tipo_sec.nombre+':</strong>'+ prop.secundarios[0].valor+' '+prop.medida+'</p>'
                                            }

                                            //}
                                            //console.log(prop.secundarios.length)

                                        }else{
                                            var cabecera='<div style="margin-right: 30px;margin-top: 30px; border-radius: 18px 18px 18px 18px;-moz-border-radius: 18px 18px 18px 18px;-webkit-border-radius: 18px 18px 18px 18px;border: 0px solid #000000;background-color: #e3e3e3;color: darkred"><Col md="6" lg="6" xl="3" ><div id="fragmento"><img className="card-img-top img-fluid" src="'+smimg3+'"/><h5 className="card-title font-16 mt-0">'+'&nbsp;'+prop.primarios.nombreSubTipo+'</h5><span>';
                                        }
                                    }
                                    if (anterior === prop.etiqueta){
                                        /*estructura=estructura+cabecera+cuerpo+pie;
                                        cuerpo=``;
                                        console.log("entra")*/
                                        //console.log(contador)

                                    //}else{
                                        estructura=estructura+cuerpo;
                                        //console.log(estructura)
                                        cuerpo=``;
                                    }else{
                                        if (contador===0){
                                            estructura=estructura+cabecera+cuerpo;
                                        }else{
                                            estructura=estructura+pie+cabecera+cuerpo;
                                        }

                                        cuerpo=``;
                                    }

                                anterior=prop.etiqueta;

                                //console.log(prop.etiqueta)






                                    //console.log(this.state.secundarios.length)
                                    //cuerpo=``;
                                    //estructura=``;
                                    //return (<div key={key}>${estructura}</div>)

                                    if (contador == this.state.secundarios.length){
                                        //console.log(estructura)
                                        document.getElementById("contenedor").innerHTML=estructura;
                                        estructura=``;
                                        cuerpo=``;
                                        contador=0;

                                        //return (<div key={key}>{estructura}</div>);
                                    }

                                }
                            )

                        }


                    </Row>


                </Container>
            </React.Fragment>

        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Index));