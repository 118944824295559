import React, { Component } from 'react';

import Layout from './components/Layout/';
import { withRouter,Route, Switch,BrowserRouter as Router  } from 'react-router-dom';
import './custom.css';
import './App.scss';
import routes from './routes';
function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component { 
    render() {
      return <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>
    }
  };
}

class App extends Component {

  componentDidMount () {
    const script = document.createElement("script");

    script.src = "https://js.pusher.com/beams/1.0/push-notifications-cdn.js";
    script.async = true;

    document.body.appendChild(script);
  }
  
  render() {
       
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        <Component {...props} />
      )} />
    )

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {routes.map((route, idx) =>
               route.ispublic ?
                 <Route path={route.path} component={route.component}  key={idx}  />
                      : 
                 <PrivateRoute path={route.path} component={withLayout(route.component)}  key={idx}  />
            )}
          </Switch>
        </Router>        
      </React.Fragment>
    );
  }
    
  
}

export default withRouter(App);
