import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import { isLarge } from '../../store/actions';
import { connect } from 'react-redux';

import logoLight from "../../images/logo-light.png";
import logoSmall from "../../images/logo-sm.png";


class Topbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            create_menu: false,
            toggle: true,
        };
        this.toggleCreate = this.toggleCreate.bind(this);
    }
    toggleCreate() {
        this.setState(prevState => ({
            create_menu: !prevState.create_menu
        }));
    }

    sidebarToggle = () => {
        document.body.classList.toggle('enlarged');
        this.props.isLarge(!this.props.is_large_state);
    }

    toggleFullscreen() {
        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="topbar">
                    <div className="topbar-left">
                        <Link to="/welcome" className="logo">
                            <span>
                                <img src={logoLight} alt="" height="28" />
                            </span>
                            <i>
                                <img src={logoSmall} alt="" height="22" />
                            </i>
                        </Link>
                    </div>

                    <nav className="navbar-custom">
                        <ul className="navbar-right list-inline float-right mb-0">
                            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block mr-1">
                                <Link onClick={this.toggleFullscreen} className="nav-link waves-effect" to="#" id="btn-fullscreen">
                                    <i className="mdi mdi-fullscreen noti-icon"></i>
                                    
                                </Link>
                            </li>
                                                        
                        </ul>
                        <ul className="list-inline menu-left mb-0">
                            <li className="float-left">
                                <button onClick={this.sidebarToggle} className="button-menu-mobile open-left waves-effect">
                                    <i className="mdi mdi-menu"></i>
                                </button>
                                
                            </li>
                            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block mr-1">
                                <Link className="nav-link waves-effect" to="/centro" id="centro">
                                Centro seleccionado: {window.localStorage.getItem("nombreCentro")}
                                </Link>
                            </li>
                                
                            
                            
                            
                        </ul>
                        
                        
                    </nav>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { is_large_state } = state.Layout;
    return { is_large_state };
}


export default (connect(mapStatetoProps, { isLarge })(Topbar));