import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Breadcrumb, BreadcrumbItem,  Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1
        }}
    />
);

let myObject = null;;
let tok='';
var secPrimarioT=[];

let feedsALLOptions=[];
let feedsALLSeries=[];
let subtiposecundarios=[];
let subtiposecundariosSerie=[];

let secundariosALL = [];
var dataALLX = [];
var dataALLSeries = [];
var tipoSecPrimario=[];

var graficos=[];



class Energia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datosALLOptions: {},
            datosALLSeries: {},
            subtiposecundariosAux: [],
            default_date: new Date(), default: false, start_date: new Date(), monthDate: new Date(), yearDate: new Date(), end_date: new Date(), date: new Date(),
            default_date_hasta: new Date(),
        }
        ;
        this.handleDefault = this.handleDefault.bind(this);
        this.handleDefaultHasta = this.handleDefaultHasta.bind(this);
    }


    handleDefault(date) {
        this.setState({ default_date: date });
    }
    handleDefaultHasta(date) {
        this.setState({ default_date_hasta: date });
    }



    componentDidMount() {
        myObject = JSON.parse(window.localStorage.getItem("token"));
        if (myObject === null) {
            tok='';
        }else{
            tok=myObject.logged.token;
        }


        this.props.activateAuthLayout();
        //console.log("LLEGA")
        this.tokenSession();
        this.cargaTiposSecPrimario();

        this.setState({
            secPrimario: []
        });



    }

    cargaTiposSecPrimario(){
        tipoSecPrimario=[];
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const cif=window.localStorage.getItem("cif");
        if (window.localStorage.getItem("tipo") === "false")
        {
            fetch(window.localStorage.getItem("url")+`/subtiposecundarioPrimario/${window.localStorage.getItem("idPrimario").replace(/['"]+/g, '')}/${cif.replace(/['"]+/g, '')}/${window.localStorage.getItem("idCentro").replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                        //console.log(result);
                    tipoSecPrimario=JSON.parse(result).SubTipoSecundarios

                        //console.log(tipoSecPrimario);
                    }
                )
                .catch(error => console.log('error', error));
        }
    }
    async tokenSession(){
        try{
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            let urlencoded = new URLSearchParams();

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(this.state),
                redirect: 'follow'
            };

            fetch(window.localStorage.getItem("url")+`/admin?jwt=${tok.replace(/['"]+/g, '')}`, requestOptions)
                .then(response => response.text())
                .then(result => {

                    //console.log(JSON.parse(result));
                    let objeto = JSON.parse(result);
                    let auxTok=objeto.token;
                    //console.log(datosOb.token);
                    if(auxTok.token === ""){
                        this.props.history.push("/");
                    }else{
                        return true;
                    }
                    return result;
                })
                .catch(error => {
                    console.log('errorAPI', error);
                    //this.props.history.push("/");
                });
        }catch(e)
        {
            console.log('error',e);
            //this.props.history.push("/");
        }


    }



    buscar=()=>{
        this.setState({
            secPrimario: []
        });
        document.getElementById('containerGraf').style.visibility = 'hidden'
        document.getElementById("botonMostrar").innerText="Mostrar gráficos"
        /*if (document.getElementById("botonMostrar").innerText === "Ocultar gráficos") {
            document.getElementById('containerGraf').style.visibility = 'hidden'
            document.getElementById("botonMostrar").innerText="Mostrar gráficos"
        }*/

        graficos=[];

        const fechaDesde=document.getElementById('fechaDesde').value.substr(6,4)+"-"+document.getElementById('fechaDesde').value.substr(3,2)+"-"+document.getElementById('fechaDesde').value.substr(0,2)
        const horaDesde=document.getElementById('horaDesde').value;
        const fechaHasta=document.getElementById('fechaHasta').value.substr(6,4)+"-"+document.getElementById('fechaHasta').value.substr(3,2)+"-"+document.getElementById('fechaHasta').value.substr(0,2)
        const horaHasta=document.getElementById('horaHasta').value;
        const cif=window.localStorage.getItem("cif");
        subtiposecundariosSerie=[];
        feedsALLOptions=[];
        feedsALLSeries=[];
        subtiposecundarios=[];
        subtiposecundariosSerie=[];

        secundariosALL = [];
        dataALLX = [];
        dataALLSeries = [];
        //document.getElementById('containerGraf').style.visibility = 'hidden'
        //document.getElementById("botonMostrar").innerText="Mostrar gráficos"
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };





        myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+tok);

        raw = "";

        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };



        //console.log(window.localStorage.getItem("url")+`/subtiposecundariotipo/${window.localStorage.getItem("idTipo").replace(/['"]+/g, '')}/${cif.replace(/['"]+/g, '')}/${window.localStorage.getItem("idCentro").replace(/['"]+/g, '')}`);
        fetch(window.localStorage.getItem("url")+`/secundariosPrimario?cif=${cif.replace(/['"]+/g, '')}&primario=${window.localStorage.getItem("idPrimario").replace(/['"]+/g, '')}&fechaI=${(fechaDesde+' '+horaDesde).replace(/['"]+/g, '')}&fechaF=${(fechaHasta+' '+horaHasta).replace(/['"]+/g, '')}&centro=${window.localStorage.getItem("idCentro").replace(/['"]+/g, '')}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                var myObject = JSON.parse(result);
                secPrimarioT=myObject.Secundarios;



            })
            .catch(error => console.log('error', error));

        this.setState({loading:false});
        setTimeout(()=>{
            this.setState({loading:false});
        },2000)



    }

    getOptions = () =>{


        if (document.getElementById("botonMostrar").innerText === "Mostrar gráficos") {

            secPrimarioT.map((prop, key) => {
                feedsALLOptions = [];
                feedsALLSeries = [];
                //console.log(prop)

                dataALLSeries = [];
                dataALLX = [];

                dataALLSeries.push({
                    name: prop.etiqueta,
                    data: []
                })

                //console.log(prop.etiqueta)
                for (let z = 0; z < dataALLSeries.length; z++) {
                    if (prop.etiqueta === dataALLSeries[z].name) {
                        for (let i = 0; i < prop.secundarios.length; i++) {
                            //console.log(secPrimario[z].secundarios[i].valor)
                            dataALLSeries[z].data.push(prop.secundarios[i].valor)
                            var date = prop.secundarios[i].fecha
                            dataALLX.push(moment(date).format('DD/MM/YYYY hh:mm:ss'))
                        }
                    }
                }


                feedsALLOptions.push({

                    colors: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf', '#FF4500', '#808000', '#D2691E', '#708090', '#DB7093', '#00CED1', '#66CDAA', '#DDA0DD', '#00FFFF', '#FFA07A', '#F0D58C', '#EEE8AA', '#FFE4E1', '#E0FFFF', '#008B8B', '#2E8B57', '#191970', '#800000', '#A0522D', ' #B22222'],
                    chart: {
                        zoom: {
                            enabled: true
                        },
                        toolbar: {
                            show: false,
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        arrayhorizontalAlign: 'right'
                    },

                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2,
                    },
                    grid: {
                        borderColor: '#f8f8fa',
                        row: {
                            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: dataALLX,
                        axisBorder: {
                            show: true
                        },
                        axisTicks: {
                            show: true
                        },
                        type: 'time'

                    }

                });

                feedsALLSeries.push({
                    series: dataALLSeries
                });




//console.log(arreglo)
                document.getElementById('containerGraf').style.visibility = 'visible';
                document.getElementById("botonMostrar").innerText="Ocultar gráficos"
                const t1 = {datos: feedsALLOptions[0]}
                const t2 = {serie: feedsALLSeries[0]}
                graficos.push(<ReactApexChart options={t1.datos} series={t2.serie.series} type="line" height="290"
                                              id={prop.tipo_sec.nombre}/>);
                //console.log(prop.tipo_sec.nombre)
                feedsALLOptions=[];
                feedsALLSeries=[];
            })

            //this.buscar();
            //this.limpiaControles();
        }else{
            graficos=[];
            document.getElementById('containerGraf').style.visibility = 'hidden'
            document.getElementById("botonMostrar").innerText="Mostrar gráficos"
        }

    }
    getDate(){
        var today = new Date();

        document.getElementById("fechaHasta").value = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
        document.getElementById("fechaHasta").defaultValue="11/11/2011"

    }




    render() {

        return (

            <React.Fragment>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <h4 className="page-title">Dashboard {window.localStorage.getItem("nombre").replace(/['"]+/g, '')}</h4>

                                <Breadcrumb>
                                    <BreadcrumbItem active>Bienvenido al dashboard de codev </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                    <Form>
                        <FormGroup row>
                            <Label htmlFor="fechaDesde" sm="0">Fecha desde</Label>
                            <Col>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.default_date}
                                    onChange={this.handleDefault}
                                    dateFormat="dd/MM/yyyy"
                                    id="fechaDesde"
                                />
                            </Col>
                            <Label htmlFor="horaDesde" sm="0">Hora</Label>
                            <Col>
                                <Input type="time" defaultValue="13:45:00" id="horaDesde" />
                            </Col>
                            <Label htmlFor="fechaHasta" sm="0">Fecha hasta</Label>
                            <Col >
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.default_date_hasta}
                                    onChange={this.handleDefaultHasta}
                                    dateFormat="dd/MM/yyyy"
                                    id="fechaHasta"
                                />
                            </Col>
                            <Label htmlFor="horaHasta" sm="0">Hora</Label>
                            <Col >
                                <Input type="time" defaultValue="13:45:00" id="horaHasta" />
                            </Col>
                            <Col>
                                <Button className="btn btn-primary waves-effect waves-light" block sm="1" onClick={this.buscar}>Buscar</Button>
                            </Col>

                        </FormGroup>

                    </Form>
                    <ColoredLine color="#FCEAE6" />

                    <Button className="btn btn-secondary waves-effect waves-light" block sm="1" onClick={this.getOptions} id="botonMostrar">
                        <span>Mostrar gr&aacute;ficos</span>
                    </Button>

                    <div id="containerGraf" style={{visibility: "visible", padding: 25}} >
                    <Row>


                        {graficos.map((prop, key) => {
                            //console.log(prop)


                            return (
                                <Col lg="6" key={key}>
                                    <Card>
                                        <CardBody>

                                            <h4 className="mt-0 header-title">{prop.props.id}</h4>
                                            {
                                                prop

                                            }

                                        </CardBody>
                                    </Card>
                                </Col>
                            );


                        })}

                    </Row>
                    </div>
                </Container>
            </React.Fragment>

        );
    }
}


export default withRouter(connect(null, { activateAuthLayout })(Energia));